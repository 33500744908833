.logoutModal{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    z-index: 99;
}

.logoutModal > div {
    width: 90%;
    height: 50%;
    max-width: 500px;
    background-color: white;
    border-radius: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logoutModal > div > p {
    max-width: 80%;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: center;
}
.buttons {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10vh;
}

.buttons > button, .buttons > a {
    width: 30%;
    height: 6vh;
    border: 1px solid #3c5a9f;
    color: #3c5a9f;
    justify-content: center;
    align-items: center;
    display: flex;
}