.stcContainerLang {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stcContainerLang > p {
  font-weight: 600;
  font-size: 2.25vh;
}

.langSelection {
  width: 25vw;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.langSelection.mob {
  width: 50vw;
}

.langSelection > button {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.25vh solid #002883;
  margin-bottom: 2vh;
}

.stcContainerLang > button {
  width: 20%;
  height: 5vh;
  margin-top: 5vh;
  background-color: #002883;
  color: white;
}

.stcContainerLang.mob > button {
  width: 50% !important;
}
