.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modal-container {
  /* position: fixed; */
  /* top: 0;
    left: 0; */
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* z-index: 999; */
}
/* .modal-container.mob > video {
    height: 70vh;
  } */
.modal-now {
  width: 40vw;
  height: 65vh;
  background-color: white;
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-now.mob {
  width: 100%;
}

.modal-now > h5 {
  width: 80%;
  text-align: center;
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.instructions-image {
  height: 65%;
  object-fit: contain;
}

video.instructions-image::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.indicator {
  margin-bottom: 2vh;
  width: 40vw;
  height: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}
.indicator.mob {
  width: 90%;
}
.indicator-item {
  flex: 1;
  height: 100%;
}
.indicator-item.selected {
  background-color: grey;
}

.title-indicator {
  font-size: 2vh;
  margin-bottom: 2vh;
  font-weight: 600;
}

.audio-file {
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 80%;
  height: 5vh;
  background-color: #405da2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.play-icon {
  width: 5vh;
  height: 5vh;
  background-color: #0c3288;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-rack {
  height: 100%;
  flex: 1;
  margin-left: 1vw;
  margin-right: 1vw;
  display: flex;
  align-items: center;
  position: relative;
}

.audio-line {
  width: 100%;
  height: 1px;
  background-color: white;
}

.audio-dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
}

.buttonsModal {
  margin-top: 2vh;
  width: 40vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.buttonsModal.mob {
  width: 100%;
}

.modalButton {
  all: unset;
  cursor: pointer;
  width: 33% !important;
  height: 100%;
  border: 1px solid #002883;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalButton > p {
  width: 100%;
  text-align: center;
  font-size: 2vh;
}

.captureBtn {
  width: 20%;
  height: 5vh;
  margin-top: 5vh;
}

.modalButton.captureBtn.analyze {
  width: 40% !important;
}

.captureBtn.mob {
  width: 80% !important;
}

.cropImage {
  width: 100%;
  height: 75vh;
}

.cropIMG {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cropImage.mob {
  height: 85vh;
}

.cropper {
  align-self: center;
  width: 100%;
  /* background-color: red; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 70%; */
}

.cropper.mob {
  width: 100%;
}

.canvas {
  max-height: 85%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
